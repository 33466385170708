import {JSEnum, TimeReference, Vehicle} from '@ambler/shared'
import {PricingType} from '@prisma/client'

export const TimeReferenceType: JSEnum<TimeReference> = {
  DEPARTURE: 'DEPARTURE',
  ARRIVAL: 'ARRIVAL',
  UNKNOWN: 'UNKNOWN',
}

export const ZIndexes = {
  NORMAL: 0,
  ABSOLUTE: 50,
  FIXED: 100,
  MODALS: 150,
  ERRORS: 300,
}

export const VehicleType: JSEnum<Vehicle> = {
  AMBULANCE: 'AMBULANCE',
  VSL: 'VSL',
  TAXI: 'TAXI',
  TPS: 'TPS',
  TPMR: 'TPMR',
  BARIATRIC: 'BARIATRIC',
  PARAMEDICAL: 'PARAMEDICAL',
  MEDICAL: 'MEDICAL',
}

export const ErrorCodeType: JSEnum<
  | 'BOOKING_LEG_NOT_FOUND'
  | 'USER_LOGGED_WITH_DIFFERENT_ACCOUNT'
  | 'BOOKING_INVALID_EPT'
  | 'BOOKING_CREATE_INBOUND_IS_BEFORE_OUTBOUND'
  | 'BOOKING_CONFIRM_TOOSOON'
  | 'EDIT_TOOSOON'
  | 'INTERMEDIATE_NOT_ASSOCIATED_TO_MT'
  | 'INVALID_IPP'
  | 'RPPS_MF_ALREADY_EXISTS'
  | 'INCOMPATIBLE_BR_STATUS'
  | 'MISSING_VEHICLE_PRICING'
  | 'ORDER_INBOUND_TOOSOON'
  | 'ORDER_RIDE_OVERLAPS_ANOTHER_ORDER_RIDE'
  | 'ORDER_RIDE_OVERLAPS_AN_EXISTING_PASSENGER'
  | 'ORDER_RIDE_HC_PERM_INBOUND'
  | 'ORDER_RIDE_TSFR_TEMP_INBOUND'
  | 'ORDER_MISSING_MANDATORY_FIELD'
  | 'USER_ALREADY_EXISTS'
  | 'INVALID_RETURN_TRIP_DEPARTURE_TIME'
  | 'FAILED_TO_VERIFY_RETURN_TRIP_DEPARTURE_TIME'
  | 'USER_MT_ACL_ALREADY_MEMBER'
> = {
  BOOKING_LEG_NOT_FOUND: 'BOOKING_LEG_NOT_FOUND',
  USER_LOGGED_WITH_DIFFERENT_ACCOUNT: 'USER_LOGGED_WITH_DIFFERENT_ACCOUNT',
  BOOKING_INVALID_EPT: 'BOOKING_INVALID_EPT',
  BOOKING_CREATE_INBOUND_IS_BEFORE_OUTBOUND: 'BOOKING_CREATE_INBOUND_IS_BEFORE_OUTBOUND',
  BOOKING_CONFIRM_TOOSOON: 'BOOKING_CONFIRM_TOOSOON',
  EDIT_TOOSOON: 'EDIT_TOOSOON',
  INTERMEDIATE_NOT_ASSOCIATED_TO_MT: 'INTERMEDIATE_NOT_ASSOCIATED_TO_MT',
  INVALID_IPP: 'INVALID_IPP',
  RPPS_MF_ALREADY_EXISTS: 'RPPS_MF_ALREADY_EXISTS',
  INCOMPATIBLE_BR_STATUS: 'INCOMPATIBLE_BR_STATUS',
  MISSING_VEHICLE_PRICING: 'MISSING_VEHICLE_PRICING',
  ORDER_INBOUND_TOOSOON: 'ORDER_INBOUND_TOOSOON',
  ORDER_RIDE_OVERLAPS_ANOTHER_ORDER_RIDE: 'ORDER_RIDE_OVERLAPS_ANOTHER_ORDER_RIDE',
  ORDER_RIDE_OVERLAPS_AN_EXISTING_PASSENGER: 'ORDER_RIDE_OVERLAPS_AN_EXISTING_PASSENGER',
  ORDER_RIDE_HC_PERM_INBOUND: 'ORDER_RIDE_HC_PERM_INBOUND',
  ORDER_RIDE_TSFR_TEMP_INBOUND: 'ORDER_RIDE_TSFR_TEMP_INBOUND',
  ORDER_MISSING_MANDATORY_FIELD: 'ORDER_MISSING_MANDATORY_FIELD',
  USER_ALREADY_EXISTS: 'USER_ALREADY_EXISTS',
  INVALID_RETURN_TRIP_DEPARTURE_TIME: 'INVALID_RETURN_TRIP_DEPARTURE_TIME',
  FAILED_TO_VERIFY_RETURN_TRIP_DEPARTURE_TIME: 'FAILED_TO_VERIFY_RETURN_TRIP_DEPARTURE_TIME',
  USER_MT_ACL_ALREADY_MEMBER: 'USER_MT_ACL_ALREADY_MEMBER',
}

export const pricingTypeOptions: Record<PricingType, string> = {
  CPAM: 'Socle CPAM',
  CPAM_SEFI_GEO: 'CPAM majoré (SEFi et geoloc)',
  CUSTOM: 'Personnalisé',
}
