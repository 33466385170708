import React from 'react'
import {filter} from 'graphql-anywhere'
import hoistNonReactStatics from 'hoist-non-react-statics'
import {FixType} from '@ambler/shared'

export function withFragmentFilter<T>(fragments: T) {
  return function _withFragmentFilter(Component: FixType) {
    function WithFragmentFilter(props: FixType) {
      const usedFragments = typeof fragments === 'function' ? fragments(props) : fragments
      const filteredProps = React.useMemo(
        () =>
          Object.keys(props).reduce((newProps, propKey) => {
            // @ts-ignore FixType
            newProps[propKey] =
              usedFragments[propKey] && props[propKey] ? filter(usedFragments[propKey], props[propKey]) : props[propKey]
            return newProps
          }, {}),
        [props, usedFragments],
      )

      return <Component {...filteredProps} />
    }

    hoistNonReactStatics(WithFragmentFilter, Component)
    WithFragmentFilter.fragments = fragments

    return WithFragmentFilter
  }
}
