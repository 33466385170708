import React from 'react'
import gql from 'graphql-tag'
import Head from 'next/head'

import {useRouter} from 'next/router'
import {withFragmentFilter} from '../with-fragment-filter'
import {
  A4T_FaviconRequestsBookingRequestFragment,
  A4T_FaviconRequestsChangeRequestFragment,
} from './favicon-requests.generated'

export const FaviconRequests = withFragmentFilter({
  bookingRequests: gql`
    fragment A4T_FaviconRequestsBookingRequestFragment on BookingRequest {
      id
    }
  `,
  changeRequests: gql`
    fragment A4T_FaviconRequestsChangeRequestFragment on ChangeRequest {
      id
      changes {
        id
        passenger {
          id
          direction
        }
      }
    }
  `,
})(
  ({
    bookingRequests,
    changeRequests,
    mtId,
    onCustomize,
  }: {
    bookingRequests: A4T_FaviconRequestsBookingRequestFragment[]
    changeRequests: A4T_FaviconRequestsChangeRequestFragment[]
    mtId: string
    onCustomize: (hasAnyRequest: boolean) => void
  }) => {
    const [isActive, setIsActive] = React.useState(false)
    const router = useRouter()

    React.useEffect(() => {
      const requestsCount = changeRequests.length + bookingRequests.length

      if (onCustomize) {
        onCustomize(requestsCount > 0)
        setIsActive(requestsCount > 0)
      }
    }, [bookingRequests.length, changeRequests.length, mtId, onCustomize])

    if (!isActive) {
      return null
    }

    const title = (() => {
      if (changeRequests?.length) {
        const currentCrId = router.query['cr-id'] ?? changeRequests[0].id
        const currentCr = changeRequests.find(cr => cr.id === currentCrId)

        return currentCr?.changes?.length === 2 ? 'Aller-retour modifié' : 'Trajet modifié'
      }

      return 'Nouvelle mission'
    })()

    return (
      <Head>
        <link rel="icon" type="image/png" href="/static/favicon/favicon-warn-32x32.png" />
        <title>{title}</title>
      </Head>
    )
  },
)
