import React, {createContext, useContext} from 'react'
import styled from 'styled-components'
import {Container, Col, Row, ScreenClassProvider, ScreenClassRender, setConfiguration} from 'react-grid-system'
import {isIn, ScreenClassUnion} from '@ambler/shared'
import {mediaQueries} from '@ambler/andive-next'

const ScreenClassContext = createContext<ScreenClassUnion>('md')

export function ResponsiveProvider({children}: {children: React.ReactNode}) {
  return (
    <ScreenClassProvider>
      <ScreenClassRender
        render={(value: ScreenClassUnion) => (
          <ScreenClassContext.Provider value={value}>{children}</ScreenClassContext.Provider>
        )}
      />
    </ScreenClassProvider>
  )
}

export function initializeResponsive() {
  setConfiguration({
    breakpoints: [576, 768, 992, 1440],
    containerWidths: [540, 750, 960, 1408],
  })
}

export const MAX_CONTENT_WIDTH = 1440

export function useScreenClass() {
  return useContext(ScreenClassContext)
}

export function useMobile() {
  const screenClass = useContext(ScreenClassContext)
  return isIn(['xs', 'sm', 'md'], screenClass)
}

export function useDesktop() {
  const screenClass = useContext(ScreenClassContext)
  return isIn(['lg', 'xl'], screenClass)
}

const AppContainerRoot = styled(Container)<{
  ref: React.Ref<Container>
  $horizontalPadding?: number
  $mobilePadding?: number
  $maxWidth?: string
}>`
  width: 100%;
  max-width: ${props => props.$maxWidth ?? `${MAX_CONTENT_WIDTH}px`};
  padding-left: ${({$horizontalPadding}) => $horizontalPadding ?? 16}px !important;
  padding-right: ${({$horizontalPadding}) => $horizontalPadding ?? 16}px !important;

  @media ${mediaQueries.mobile} {
    padding-left: ${({$mobilePadding, $horizontalPadding}) => $mobilePadding ?? $horizontalPadding ?? 0}px !important;
    padding-right: ${({$mobilePadding, $horizontalPadding}) => $mobilePadding ?? $horizontalPadding ?? 0}px !important;
  }
`

export const AppContainer = React.forwardRef(function AppContainerComponent(
  {
    horizontalPadding,
    mobilePadding,
    ...props
  }: {
    children: React.ReactNode
    horizontalPadding?: number
    mobilePadding?: number
  },
  ref: React.Ref<Container>,
) {
  return (
    <AppContainerRoot
      ref={ref}
      fluid
      $horizontalPadding={horizontalPadding}
      $mobilePadding={mobilePadding}
      {...props}
    />
  )
})

// ? Filter out unecessary props that should not be forwarded to <Col/>, and
// ? especially to the DOM.
// ? See for more details why the align prop must never be forwarded to the <div/> HTML tag.
// ? - https://html.spec.whatwg.org/multipage/rendering.html#flow-content-3:attr-div-align
const AppColRoot = styled(Col)<{$align: string; $justify: string; $direction?: string}>`
  display: flex;
  flex-direction: ${({$direction}) => $direction ?? 'row'};
  align-items: ${({$align}) => $align};
  justify-content: ${({$justify}) => $justify};
`
export const AppCol = ({justify = 'flex-start', align = 'center', direction = 'row', ...props}) => (
  <AppColRoot $justify={justify} $align={align} $direction={direction} {...props} />
)

const AppRowRoot = styled(Row)<{
  ref: any
  $align?: string
  $justify?: string
  $direction?: string
  $desktopPadding?: number
  $mobilePadding?: number
  $horizontalPadding?: number
}>`
  /* This is sad but we need to take precedence over the react-grid-system Row component
     with "!important". From the doc FAQ: https://styled-components.com/docs/faqs#how-can-i-override-inline-styles.
     They say to use the element-attr trick, but !important looks enough.
     We may need to rethink how we layout (skip react-grid-system or assimilate it into @ambler/andive).
  */
  display: flex !important;
  flex-direction: ${({$direction}) => $direction ?? 'row'} !important;
  align-items: ${({$align}) => $align} !important;
  justify-content: ${({$justify}) => $justify} !important;

  padding-left: ${({$desktopPadding, $horizontalPadding}) => $desktopPadding ?? $horizontalPadding ?? 16}px !important;
  padding-right: ${({$desktopPadding, $horizontalPadding}) => $desktopPadding ?? $horizontalPadding ?? 16}px !important;

  @media ${mediaQueries.mobile} {
    padding-left: ${({$mobilePadding, $horizontalPadding}) => $mobilePadding ?? $horizontalPadding ?? 16}px !important;
    padding-right: ${({$mobilePadding, $horizontalPadding}) => $mobilePadding ?? $horizontalPadding ?? 16}px !important;
  }
`

export const AppRow = React.forwardRef(function AppRowComponent(
  {
    align,
    justify,
    direction,
    desktopPadding,
    mobilePadding,
    horizontalPadding,
    ...props
  }: {
    children: React.ReactNode
    direction?: string
    justify?: string
    align?: string
    desktopPadding?: number
    mobilePadding?: number
    horizontalPadding?: number
  },
  ref,
) {
  return (
    <AppRowRoot
      ref={ref}
      nogutter
      $align={align}
      $justify={justify}
      $direction={direction}
      $desktopPadding={desktopPadding}
      $mobilePadding={mobilePadding}
      $horizontalPadding={horizontalPadding}
      {...props}
    />
  )
})
